
import React, { Component } from "react"
import IVLogo from "../images/IV-Solution-Logo.svg"
import Phone from "../images/phone.svg"
import MenuDevice from "../components/menuDevice"
import { Link } from "gatsby"

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = { showMenu: false };
        this.handleClick = this.handleClick.bind(this);
    }

    // function to handle the click
    handleClick() {
        const burgerMenu = document.querySelector('.hamburger')
        const deviceMenu = document.querySelector('.menu-device')
        burgerMenu.classList.toggle('is-active')
        deviceMenu.classList.toggle('is-open')
    };




    // the render() method to put stuff into the DOM
    render() {
        const Logo = () => <><img style={{ width: 58 }} src={IVLogo} alt='IV Solutions Logo' /></>
        const PhoneCta = () => <><img style={{ width: 29, marginRight: 9 }} src={Phone} alt='phone icon' /></>


        const Hamburger = () => (
            <button className={`hamburger hamburger--slider ${this.state.showMenu ? 'is-active' : ''}`} onClick={this.handleClick}>
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>

        )

        const Navigation = () => (
            <div className="sticky-top no-stick--mobile" id="home">
                <div className="top-stripe d-none d-lg-flex justify-content-between align-items-center">
                    <p className="text-top">Clinically Proven IV Treatment for Depression, Anxiety, PTSD and more.</p>
                    {
                        typeof window !== `undefined` && (window.location.pathname === "/landing" || window.location.pathname === "/landing/")?
                        <a className="phone-cta desktop" href="tel:+18443263577"><PhoneCta />(844) 326-3577</a>
                        :
                        <a className="phone-cta desktop" href="tel:+18446873646"><PhoneCta />(844) 687-3646</a>
                    }
                </div>
                <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-white">
                    <Link className="d-lg-none" to="/"><Logo /></Link>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <Link className="navbar-brand d-none d-lg-block mt-2 mb-2" to="/"><Logo /></Link>
                        <ul className="navbar-nav">
                        <li className="nav-item">
                                <Link className="nav-link text-dark font-weight-bold top-nav-item" to='/'>Start Here</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-dark font-weight-bold top-nav-item" to='/what-to-expect'>What to Expect</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-dark font-weight-bold top-nav-item" to='/testimonials'>Testimonials</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-dark font-weight-bold top-nav-item" to='/faq'>FAQ</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-dark font-weight-bold top-nav-item" to='/research'>Clinical Research</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-dark font-weight-bold top-nav-item" to='/locations'>Locations</Link>
                            </li>
                        </ul>
                        <div className="d-flex justify-content-between">
                            <Link className="btn btn-secondary my-2 my-sm-0 d-none d-lg-block ml-4" to="/quiz-new">Neurological <br /> Health Quiz</Link>
                            <Link className="btn btn-success my-2 my-sm-0 d-none d-lg-block ml-4" to="/consultation">Schedule a <br /> Consultation</Link>
                        </div>


                    </div>
                    <Hamburger style={{ paddingTop: `10px` }} />
                </nav>
                <MenuDevice />
            </div>
        )
        return (
            <Navigation />
        );
    }
}

export default Navigation;
