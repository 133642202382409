import React from "react"
import { Link } from "gatsby"
import TopCta from "../components/TopCta"

const MenuDevice = () => (
    <div className='menu-device'>
        <nav className="nav flex-column">
            <div className='d-flex align-items-center select-category'></div>
            <Link className="nav-link text-dark d-flex align-items-center device-item font-weight-bold" to="/"><span className='ml-3 font-size-16'>Start Here</span></Link>
            <Link className="nav-link text-dark d-flex align-items-center device-item font-weight-bold" to="/what-to-expect"><span className='ml-3 font-size-16'>What to Expect</span></Link>
            <Link className="nav-link text-dark d-flex align-items-center device-item font-weight-bold" to="/testimonials"><span className='ml-3 font-size-16'>Testimonials</span></Link>
            <Link className="nav-link text-dark d-flex align-items-center device-item font-weight-bold" to="/faq"><span className='ml-3 font-size-16'>FAQ</span></Link>
            <Link className="nav-link text-dark d-flex align-items-center device-item font-weight-bold" to="/research"><span className='ml-3 font-size-16'>Clinical Research</span></Link>
            <Link className="nav-link text-dark d-flex align-items-center device-item font-weight-bold border-bottom " to="/locations"><span className='ml-3 font-size-16'>Locations</span></Link>
            <Link className="btn btn-secondary m-4" to="/quiz-new">Neurological <br/> health quiz</Link>
        </nav>
        <TopCta />
    </div>
)

export default MenuDevice;


