import React from "react"
import { Link } from "gatsby"
import Phone from "../images/phone.svg"


const PhoneCta = () => <><img style={{ width: 29, marginRight: 9 }} src={Phone} alt='phone icon' /></>

const TopCta = () => {
    return (
        <>
            <div className="cta-top--stripe d-flex d-lg-none justify-content-between sticky-top">
                <div className="d-flex flex-column align-items-center justify-content-center cta-bottom is-yellow p-1">
                    <Link className="font-weight-bold cta-consultation--bottom" to="/consultation">Schedule a consultation</Link>
                </div>
                {
                    typeof window !== `undefined` &&(window.location.pathname === "/landing" || window.location.pathname === "/landing/" )?
                        <a className=" phone-cta d-flex flex-column align-items-center justify-content-center cta-bottom is-purple p-2" href="tel:+18443263577">
                            <div className="font-weight-bold"><PhoneCta />(844) 326-3577</div>
                        </a>
                        :
                        <a className=" phone-cta d-flex flex-column align-items-center justify-content-center cta-bottom is-purple p-2" href="tel:+18446873646">
                            <div className="font-weight-bold"><PhoneCta />(844) 687-3646</div>
                        </a>
                }
            </div>
        </>
    );
}

export default TopCta